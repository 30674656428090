<template>
  <div id="app">
    <router-view />
    <div class="footer">
      主办: 河北省应急管理厅 <br />
      冀ICP备14020218号 冀公网安备 13011002000239号
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.footer {
  min-height: 190px;
  background: #333333;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
}
</style>
