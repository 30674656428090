<template>
  <div class="home">
    <div class="home-banner">
      <img src="../assets/logo.png" class="logo" />
    </div>
    <div class="container">
      <div class="section">
        <div class="section-title">
          <img src="../assets/title-1.png" />
        </div>
        <div class="section-content">
          <div class="guide-box-top">
            <div class="guide-box-left">
              <!-- 轮播图 -->
              <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="true">
                <div class="carousel-indicators">
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"
                    aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                    aria-label="Slide 2"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
                    aria-label="Slide 3"></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="../assets/company/slider.png" class="d-block w-100" />
                  </div>
                  <div class="carousel-item">
                    <img src="../assets/company/slider.png" class="d-block w-100" />
                  </div>
                  <div class="carousel-item">
                    <img src="../assets/company/slider.png" class="d-block w-100" />
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
              <div>
                <a class="mr" href="#/companylist"><img src="../assets/company/home-province.png" /></a>
                <a href="#/companylist"><img src="../assets/company/home-country.png" /></a>
              </div>
            </div>
            <div class="guide-box-right">
              <a href="#/companynotice"><img src="../assets/company/home-notice.png" /></a>
              <a href="#/companynotice"><img src="../assets/company/home-policy.png" /></a>
              <a data-bs-toggle="modal" data-bs-target="#ModalWait"><img src="../assets/company/home-apply.png" /></a>
            </div>
          </div>
          <div class="guide-box-bottom">
            <div class="guide-box-left">
              <a href="#/companynotice"><img src="../assets/company/home-society.png" /></a>
            </div>
            <div class="guide-box-right">
              <a> <img src="../assets/company/home-expert.png" /></a>
            </div>
          </div>
          <div class="company-excellent">
            <div class="company-title" bindtap="toCompanyExcellent">
              企业风采
            </div>
            <div class="company-news-list">
              <div class="company-news-item">
                <img src="/pages/images/arrow-right-blue.png" class="company-news-pic" />
                <div>
                  <div class="company-news-title">
                    河北省应急管理厅关于印发《河北省企...
                  </div>
                  <div class="company-news-time">2022-09-01 10:20:11</div>
                </div>
              </div>
              <div class="company-news-item">
                <img src="/pages/images/arrow-right-blue.png" class="company-news-pic" />
                <div>
                  <div class="company-news-title">
                    河北省应急管理厅关于印发《河北省企...
                  </div>
                  <div class="company-news-time">2022-09-01 10:20:11</div>
                </div>
              </div>
              <div class="company-news-item">
                <img src="/pages/images/arrow-right-blue.png" class="company-news-pic" />
                <div>
                  <div class="company-news-title">
                    河北省应急管理厅关于印发《河北省企...
                  </div>
                  <div class="company-news-time">2022-09-01 10:20:11</div>
                </div>
              </div>
              <div class="company-news-item">
                <img src="/pages/images/arrow-right-blue.png" class="company-news-pic" />
                <div>
                  <div class="company-news-title">
                    河北省应急管理厅关于印发《河北省企...
                  </div>
                  <div class="company-news-time">2022-09-01 10:20:11</div>
                </div>
              </div>
            </div>
            <div class="company-more" bindtap="toCompanyExcellent">
              查看更多 <i class="iconfont icon-youjiantou"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="section section-safety">
        <div class="section-title">
          <img src="../assets/title-2.png" />
        </div>
        <div class="section-content">
          <div class="guide-box-top">
            <div class="guide-box-left">
              <!-- 轮播图 -->
              <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="true">
                <div class="carousel-indicators">
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"
                    aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                    aria-label="Slide 2"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
                    aria-label="Slide 3"></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="../assets/company/slider.png" class="d-block w-100" />
                  </div>
                  <div class="carousel-item">
                    <img src="../assets/company/slider.png" class="d-block w-100" />
                  </div>
                  <div class="carousel-item">
                    <img src="../assets/company/slider.png" class="d-block w-100" />
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
              <!-- 资讯动态 -->
              <div class="news-section" bindtap="showMoreNews">
                <span>资讯动态</span>
                <i class="iconfont icon-youjiantou"></i>
              </div>
              <div class="news-list">
                <div class="news-card" bindtap="toNewsDetail">
                  <img class="news-image" src="" />
                  <span class="news-title">省应急物资供应中心开展储备库维修工程省应急物资供应...</span>
                </div>
                <div class="news-card" bindtap="toNewsDetail">
                  <img class="news-image" src="" />
                  <span class="news-title">省应急物资供应中心开展储备库维修工程省应急物资供应...</span>
                </div>
              </div>
            </div>
            <div class="guide-box-right">
              <a data-bs-toggle="modal" data-bs-target="#ModalScan"><img src="../assets/safety/home-answer.png" /></a>
              <a><img src="../assets/safety/home-video.png" /></a>
              <a> <img src="../assets/safety/home-siji.png" /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="section section-vol">
        <div class="section-title">
          <img src="../assets/title-3.png" />
        </div>
        <div class="section-content">
          <div class="guide-box-top">
            <div class="guide-box-left">
              <!-- 轮播图 -->
              <div id="carouselExampleIndicators1" class="carousel slide" data-bs-ride="true">
                <div class="carousel-indicators">
                  <button type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide-to="0" class="active"
                    aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide-to="1"
                    aria-label="Slide 2"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide-to="2"
                    aria-label="Slide 3"></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="../assets/company/slider.png" class="d-block w-100" />
                  </div>
                  <div class="carousel-item">
                    <img src="../assets/company/slider.png" class="d-block w-100" />
                  </div>
                  <div class="carousel-item">
                    <img src="../assets/company/slider.png" class="d-block w-100" />
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators1"
                  data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators1"
                  data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
              <div></div>
            </div>
            <div class="guide-box-right">
              <a class="item">
                <img src="../assets/volunteer/home-icon-event.png" />
                找活动
              </a>
              <a class="item">
                <img src="../assets/volunteer/home-icon-org.png" />
                找组织
              </a>
              <a class="item" data-bs-toggle="modal" data-bs-target="#ModalWait">
                <img src="../assets/volunteer/home-icon-require.png" />
                发需求
              </a>
              <a class="item" data-bs-toggle="modal" data-bs-target="#ModalWait">
                <img src="../assets/volunteer/home-icon-rank.png" />
                爱心榜
              </a>
            </div>
          </div>
          <div class="guide-box-bottom">
            <a> <img src="../assets/volunteer/home-person.png" /></a>
            <a><img src="../assets/volunteer/home-org.png" /></a>
          </div>
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane"
                type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">
                省级专项活动
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane"
                type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">
                地市精彩活动
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab"
              tabindex="0">
              <div class="vol-news-list">
                <div class="vol-news-item" bindtap="toEventDetail">
                  <img src="/pages/images/ad.png" class="vol-news-pic" />
                  <span class="vol-news-title">安全进万家 幸福你我他</span>
                  <span class="vol-news-time">2022-09-01 10:20:11</span>
                </div>
                <div class="vol-news-item" bindtap="toEventDetail">
                  <img src="/pages/images/ad.png" class="vol-news-pic" />
                  <span class="vol-news-title">安全进万家 幸福你我他</span>
                  <span class="vol-news-time">2022-09-01 10:20:11</span>
                </div>
                <div class="vol-news-item" bindtap="toEventDetail">
                  <img src="/pages/images/ad.png" class="vol-news-pic" />
                  <span class="vol-news-title">安全进万家 幸福你我他</span>
                  <span class="vol-news-time">2022-09-01 10:20:11</span>
                </div>
                <div class="vol-news-item" bindtap="toEventDetail">
                  <img src="/pages/images/ad.png" class="vol-news-pic" />
                  <span class="vol-news-title">安全进万家 幸福你我他</span>
                  <span class="vol-news-time">2022-09-01 10:20:11</span>
                </div>
              </div>
              <div class="vol-more" bindtap="toEventProv">
                查看更多 <i class="iconfont icon-youjiantou"></i>
              </div>
            </div>
            <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
              ...
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade modal-wait" id="ModalWait" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center">敬请期待</div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade modal-wait" id="ModalScan" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center">
            <img src="../assets/gh.jpg" /><br />
            打开手机微信扫一扫<br />
            参加应急安全知识网络竞赛
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import {
    fetchBanner
  } from "@/network/API.js";
  export default {
    data() {
      return {
        bannerList: [],
      }
    },
    created() {
      this.getBanner()
    },
    methods: {
      async getBanner() {
        let data = await fetchBanner({
          type_id: 1
        })
      },


    }
  };
</script>
<style>
  /* 
 */

  .carousel-inner {
    width: 750px;
    border-radius: 10px;
  }

  .home-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 260px;
    background: url(../assets/home-banner.png) bottom center no-repeat;
  }

  .logo {
    transform: scale(0.8);
  }

  .section {
    margin: 0 -12px;
  }

  .section-title {
    padding: 20px 0;
  }

  .guide-box-top,
  .guide-box-bottom {
    display: flex;
    justify-content: space-between;
  }

  .guide-box-top a,
  .guide-box-bottom a {
    margin: 15px 0;
    display: inline-flex;
    cursor: pointer;
    transition: all linear 0.2s;
  }

  .guide-box-top a:hover img,
  .guide-box-bottom a:hover img {
    transform: translateY(-5px);
    transition: all linear 0.2s;
  }

  .guide-box-top .guide-box-left {
    display: flex;
    flex-direction: column;
  }

  .guide-box-top .carousel {
    margin: 15px 0;
  }

  .guide-box-top .guide-box-right {
    width: 360px;
  }

  .guide-box-bottom .guide-box-right {
    width: 750px;
  }

  .guide-box-bottom .guide-box-left {
    width: 360px;
  }

  .guide-box-top a.mr {
    margin-right: 30px;
  }

  /* .carousel-indicators{
  background: #3AA2E1;
} */
  /* 志愿者 */
  .section-vol .guide-box-top .guide-box-right {
    background: linear-gradient(180deg,
        rgba(255, 175, 80, 0.3) 0%,
        rgba(255, 255, 255, 0.3) 100%);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 30px;
    height: 390px;
    margin: 15px 0;
  }

  .section-vol .guide-box-top .guide-box-right .item {
    width: 90px;
    margin: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #000;
    text-decoration: none;
    font-size: 21px;
  }

  .section-vol .guide-box-top .guide-box-right .item img {
    margin-bottom: 10px;
  }

  .vol-news-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  .vol-news-item {
    width: 555px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background: #fff;
    margin: 15px;
    transition: all linear 0.2s;
  }

  .vol-news-item:hover {
    cursor: pointer;
    transform: translateY(-5px);
  }

  .vol-news-pic {
    width: 690px;
    height: 360px;
  }

  .vol-news-title {
    padding: 30px 30px 10px 30px;
    font-size: 26px;
    line-height: 1.6em;
  }

  .vol-news-time {
    font-size: 21px;
    color: #9e9e9e;
    padding: 0 30px 30px 30px;
  }

  /* 志愿者更多 */
  .vol-more {
    color: #ff3241;
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 30px 0;
    cursor: pointer;
    transition: all linear 0.2s;
  }

  .vol-more:hover {
    transform: translateY(-5px);
  }

  .vol-more .iconfont {
    margin-left: 10px;
    font-size: 30px;
  }

  .nav-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .nav-tabs .nav-link {
    font-size: 21px;
    border: none;
    color: #222;
    margin: 0 20px;
  }

  .nav-tabs .nav-link.active {
    color: #ff3241;
    border: none;
    border-bottom: 6px solid #ff3241;
  }

  /* 企业名录新闻列表 */

  .company-news-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  .company-news-item {
    width: 555px;
    margin: 15px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    padding: 30px;
    transition: all linear 0.2s;
    text-decoration: none;
    color: #333;
  }

  .company-news-item:hover {
    cursor: pointer;
    transform: translateY(-5px);
  }

  .company-news-pic {
    width: 200px;
    height: 140px;
    margin-right: 20px;
  }

  .company-news-title {
    font-size: 21px;
    line-height: 1.6em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 10px;
  }

  .company-news-time {
    font-size: 16px;
    color: #9e9e9e;
  }

  /* 资讯动态 */

  .news-section {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
    background: #fff;
    transition: all linear 0.2s;
  }

  .news-section:hover {
    cursor: pointer;
    transform: translateY(-5px);
  }

  .news-section span {
    color: #005dab;
    font-size: 30px;
    font-weight: 600;
  }

  .news-section i {
    font-size: 30px;
    color: #005dab;
  }

  .news-list {
    display: flex;
    margin: 0 -15px;
  }

  .news-card {
    flex: 0 0 360px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 15px 15px;
    width: 360px;
    transition: all linear 0.2s;
  }

  .news-card:hover {
    cursor: pointer;
    transform: translateY(-5px);
  }

  .news-image {
    width: 360px;
    height: 220px;
    border-radius: 20px;
  }

  .news-title {
    font-size: 21px;

    max-lines: 2;
    margin: 20px 0;
    color: #333333;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .modal-wait .modal-header {
    border-bottom: none;
  }

  .modal-wait .modal-body {
    min-height: 100px;
  }
</style>