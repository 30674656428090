import {
    get,
    post
} from '@/network/request.js';
export const fetchBanner = p => get('general/banner', p)
export const paperList = p => get('paper/index', p)
export const paperDetail = p => get('paper/detail/' + p, {});
// 风采/通知/政策 收藏
export const toPaperFavourite = p => post('paper/favourite', p);
//通告分类
export const notificationCategory = p => get('paper/subType?code=' + p, {});
//企业名录列表
export const companyList = p => get('company/index', p)
//企业名录详情
export const companyDetail = p => get('company/detail/' + p, {})
//企业名录收藏
export const compaynFavourite = p => post('company/favourite', p)
//社会企业分类
export const socialCategory = p => get('paper/subType?code=social', p)
//专家列表
export const expertList = p => get('expert/index', p)
//专家详情
export const expertDetail = p => get('expert/detail/' + p, {})
//专家收藏
export const expertFavourite = p => post('expert/favourite', p)

// export const articleList = p => get('/general/article', p);
// export const articleDetail = p => get('/general/article/' + p, {})

// export const voteCategory = p => get('votes/category', p)
// export const voteList = p => get('votes/index', p)
// export const voteDetail = p => get('votes/index/' + p + '?subject_id=1', {})

// export const submitForm = p => post('profile/opusPC', p)

// export const opusList = p => get('profile/opusPC', p)
// export const login = p => post('account/pclogin', p)
// export const sendSMS = p => post('general/sms', p)
// export const bindMobile = p => post('account/complete', p)
// export const logout = p => get('account/logout', p)