export const userState = (state, user) => {
    if (user) {
        state.hasLogin = true
        state.userInfo = user
        state.token = user.token
        window.localStorage.setItem('userInfo', JSON.stringify(user))
        window.localStorage.setItem('token', user.token)
    }else{
        state.hasLogin = false
        state.userInfo = null
        state.token = ""
        window.localStorage.removeItem('userInfo')
        window.localStorage.removeItem('token')
    }
}
export const setToken = (state, token) => {
    if (token) {
        state.token = token
        state.hasLogin = true
        window.localStorage.setItem('token', token)
    }else{
        state.token = ""
        state.hasLogin = false
        window.localStorage.removeItem('userInfo')
        window.localStorage.removeItem('token')
    }
}