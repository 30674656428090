import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/companynotice',
        name: 'CompanyNotice',
        component: () =>
            import('../views/company/CompanyNotice.vue')
    },
    {
        path: '/companyarticle',
        name: 'CompanyArticle',
        component: () =>
            import('../views/company/CompanyArticle.vue')
    },
    {
        path: '/companylist',
        name: 'CompanyList',
        component: () =>
            import('../views/company/CompanyList.vue')
    },
    {
        path: '/companydetail',
        name: 'CompanyDetail',
        component: () =>
            import('../views/company/CompanyDetail.vue')
    },
    {
        path: '/expertcenter',
        name: 'ExpertCenter',
        component: () =>
            import('../views/company/ExpertCenter.vue')
    },
    {
        path: '/expertlist',
        name: 'ExpertList',
        component: () =>
            import('../views/company/ExpertList.vue')
    },
    {
        path: '/expertdetail',
        name: 'ExpertDetail',
        component: () =>
            import('../views/company/ExpertDetail.vue')
    },
    {
        path: '/videolist',
        name: 'VideoList',
        component: () =>
            import('../views/safety/VideoList.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router