// import Vue from 'vue'
import axios from 'axios'

import QS from 'qs';
import Router from '@/router/index'
import Store from '@/store/index'

import mainVue from '../main'

let baseURL
if (process.env.NODE_ENV === 'development') {
  baseURL = '/api'
} else {
  if (process.env.type === 'test') {
    baseURL = 'https://api.hbzhengwu.com/mem/api/'
  } else {
    baseURL = 'https://api.hbzhengwu.com/mem/api/'
  }
}
// axios.defaults.timeout = 6000;                
// axios.defaults.baseURL = baseURL;
// axios.defaults.responseType = "json";
// axios.defaults.withCredentials = false;

const service = axios.create({
  baseURL: baseURL, // api base_url,
  timeout: 60 * 1000, // 请求超时时间,
  headers: {
    "Content-Type": "application/json"
  },
});

service.interceptors.request.use(
  config => {
    var token = window.localStorage.getItem("token")
    if (token && token != "") {
      config.headers.token = token
    }
    config.headers.api = 'tujkkakkxkak'
    return config
  },
  error => {
    return Promise.reject(error);
  }
)

service.interceptors.response.use(
  response => {
    if (response.status == 200) {
      if (response.data.code == 20000 && response.data != null) {
        return Promise.resolve(response.data.data)
      } else {
        switch (response.data.code) {
          case 10001:
            Store.dispatch("logout");
            Router.replace({
              path: '/'
            })
            break;
          case 30001:
            Router.replace({
              path: '/'
            })
            break;
          default:
            break;
        }
        return Promise.reject(response.data.message)
      }
    } else {
      return Promise.reject(response.errMsg)
    }
  }
)



export function get(url, params) {
  return new Promise((resolve, reject) => {
    service.get(url, {
        params: params
      })
      .then(res => {
        resolve(res);
      })
      .catch(msg => {
        reject(msg)
      })
  });
}

export function post(url, params) {
  return new Promise((resolve, reject) => {
    service.post(url, params)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        // reject(err)
        mainVue.$toast(err)
      })
  });
}